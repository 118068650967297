<template>
  <div class="main-content row q-pa-md">
    <Mural
      :breadcrumbs="breadcrumbs"
      :query="{ courseId: courseId, trainingId: trainingId, postId: postId }"
      route="training-page"
    >
      <DefaultBtn
        titleButton="Salvar"
        :query="{ courseId: courseId, trainingId: trainingId, postId: postId }"
        route="new-content"
        @click="saveOrderTraining"
      />
    </Mural>
    <div class="lesson-content flex row q-mt-md col-12">
      <div style="" class="media col-sm-12 col-md-7 q-pa-md">
        <h6 class="label-content q-pa-md">Conteúdo da Aula</h6>
        <draggable :list="medias" @change="reorderTraining">
          <div
            class="row q-pa-md items-center col-12"
            v-for="(element, index) in medias"
            :key="index"
          >
            <div class="media-card-section">
              <NewContentToLessonsCard
                :content="element"
                :query="{
                  courseId: courseId,
                  trainingId: trainingId,
                  quizId: element.id,
                  evaluation: element.evaluation,
                  postId: postId,
                }"
                route="generate-evaluation"
                @publish="(requestData) => openFileSelectEdit(requestData)"
                @removeItem="(id, type) => alertRemoveMedia(id, type)"
                :date_created="element.created_at"
              />
            </div>
          </div>
        </draggable>
        <div>
          <NewContentToLessonsCardForLive
            class="q-mb-md"
            v-for="(live, index) in liveClass"
            :key="index"
            :content="live"
            :date_created="live.created_at"
            @removeItem="(id) => removeLive(id)"
          />
        </div>
      </div>
      <div
        class="col-sm-12 col-md-5 add-contents flex items-center justify-center"
      >
        <div class="justify-center">
          <h6 class="q-pa-md">Adicionar conteúdo</h6>
          <div class="row q-pa-md">
            <q-btn
              class="add-button"
              outline
              rounded
              icon="o_videocam"
              @click="openFileSelect('video')"
              align="between"
              icon-right="add"
              color="purple"
              label="Multimídia"
            />
          </div>
          <div class="row q-pa-md">
            <q-btn
              class="add-button"
              outline
              rounded
              icon="image"
              @click="openFileSelect('img')"
              align="between"
              icon-right="add"
              color="purple"
              label="Imagem"
            />
          </div>
          <div class="row q-pa-md">
            <q-btn
              class="add-button"
              outline
              rounded
              icon="description"
              icon-right="add"
              align="between"
              color="deep-orange"
              @click="openFileSelect('application')"
              label="Documento"
            />
          </div>
          <div class="row q-pa-md">
            <q-btn
              class="add-button"
              outline
              rounded
              icon="edit"
              icon-right="add"
              align="between"
              :to="{
                name: 'generate-evaluation',
                query: {
                  courseId: courseId,
                  trainingId: trainingId,
                  evaluation: false,
                  postId: postId,
                },
              }"
              color="brown-5"
              label="Quiz"
            />
          </div>
          <div class="row q-pa-md">
            <q-btn
              class="add-button"
              outline
              rounded
              align="between"
              icon-right="add"
              color="secondary"
              @click="openFileSelect('x-zip-compressed')"
            >
              <Interactivity color="var(--q-primary, #fe4e64)" />
              interatividade
            </q-btn>
          </div>
          <div class="row q-pa-md">
            <q-btn
              class="add-button"
              outline
              rounded
              align="between"
              icon="video_camera_front"
              icon-right="add"
              color="secondary"
              @click="showCreateLiveClass = true"
            >
              Aula ao vivo
            </q-btn>
          </div>
          <h6 class="">Avaliações</h6>
          <div class="q-pa-md">
            <RoundedBtn
              class="add-evaluation"
              :query="{
                courseId: courseId,
                trainingId: trainingId,
                evaluation: true,
                postId: postId,
              }"
              route="generate-evaluation"
              title-button="Criar Avaliação"
              styleButton="create-exam"
            />
          </div>
          <!--<div class="q-pa-md">
             <q-btn
              outline
              rounded
              color="deep-orange"
              label="Gerenciar banco de questões"
            /> 
          </div>-->
        </div>
      </div>
    </div>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <div class="collumn justify-center q-my-md">
        <div style="height: auto; width: 50px; margin: auto">
          <LoadingIcon />
        </div>
        <label>Por favor aguarde... {{ loadingPostValue }}%</label>
      </div>
    </q-inner-loading>
  </div>

  <q-dialog v-model="isShowGameForm" persistent>
    <q-card>
      <q-card-section>
        <q-input
          label="Título"
          v-model="postForm.titlePost"
          :rules="[
            (val) =>
              !!postForm.titlePost || 'O nome do arquivo não pode ser vazio',
          ]"
        ></q-input>
      </q-card-section>
      <q-card-section class="column items-center dialog-main">
        <h6>Adicionar um arquivo Zip</h6>
        <q-uploader
          url="http://localhost:8080/upload"
          color="default-pink"
          square
          flat
          bordered
          hide-upload-btn
          :filter="checkFileTypeZip"
          @rejected="onRejected()"
          style="max-width: 300px"
        />
      </q-card-section>

      <q-card-section class="column items-center">
        <h6>Adicione uma imagem de capa</h6>
        <q-uploader
          color="default-pink"
          :filter="imageGame"
          square
          flat
          bordered
          accept="image/*"
          hide-upload-btn
          style="max-width: 300px"
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          text-color="default-pink"
          dense
          flat
          label="Cancelar"
          color="primary"
          v-close-popup
        />
        <q-btn
          color="default-pink"
          text-color="white"
          no-caps
          unelevated
          dense
          label="Salvar"
          @click="validateAndAddGame"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <!-- <DialogMedia
    v-if="isShowMediaForm"
    :showDialog="isShowMediaForm"
  /> -->
  <q-dialog v-model="isShowMediaForm" persistent>
    <q-card>
      <q-card-section class="column items-left dialog-main">
        <h6>
          Adicionar um {{ fileType == "application" ? "Documento" : "vídeo" }}
        </h6>
        <q-uploader
          url="http://localhost:8080/upload"
          color="default-pink"
          square
          flat
          bordered
          hide-upload-btn
          :filter="checkFileType"
          @rejected="
            onRejected(
              `O formato é diferente de ${
                fileType == 'application' ? 'Documento' : 'vídeo'
              }`
            )
          "
          style="max-width: 300px"
        />
      </q-card-section>
      <q-card-section>
        <q-input label="Título" v-model="postForm.titlePost"></q-input>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          text-color="default-pink"
          dense
          flat
          label="Cancelar"
          color="primary"
          v-close-popup
        />
        <q-btn
          color="default-pink"
          text-color="white"
          no-caps
          unelevated
          dense
          label="Salvar"
          @click="validateAndAddMedia"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="isShowMedia" persistent>
    <q-card>
      <q-card-section class="column items-left dialog-main">
        <h6>Adicionar uma imagem</h6>
        <q-uploader
          url="http://localhost:8080/upload"
          color="default-pink"
          square
          flat
          bordered
          hide-upload-btn
          :filter="imageFile"
          accept="image/jpeg, png"
          @rejected="onRejected('O formato é diferente das imagens aceitas')"
          style="max-width: 300px"
        />
      </q-card-section>
      <q-card-section>
        <q-input label="Título" v-model="postForm.titlePost"></q-input>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          text-color="default-pink"
          dense
          flat
          label="Cancelar"
          color="primary"
          v-close-popup
        />
        <q-btn
          color="default-pink"
          text-color="white"
          no-caps
          unelevated
          dense
          label="Salvar"
          @click="validateAndAddMedia"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <DialogCreateLiveClass
    v-show="showCreateLiveClass"
    :showDialog="showCreateLiveClass"
    :trainingId="trainingId"
    :courseId="courseId"
    @success="getAllLiveClass"
    @close="showCreateLiveClass = false"
  />
</template>
<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import DownloadFileCard from "@/components/courses/cards/DownloadFileCard";
import MediaCard from "@/components/courses/cards/MediaCard";
import DialogMedia from "@/components/courses/dialogs/DialogMedia";

import NewContentToLessonsCard from "@/components/courses/cards/NewContentToLessonsCard";
import NewContentToLessonsCardForLive from "@/components/courses/cards/NewContentToLessonsCardForLive";
import RoundedBtn from "@/components/shared/buttons/RoundedBtn";
import LoadingIcon from "@/components/LoadingIcon.vue";
import UploadComponent from "@/components/courses/others/UploadComponent";

import TrailDataServices from "@/services/TrailDataServices";
import BreadcrumbsDataServices from "@/services/BreadcrumbsDataServices";
import { ENV } from "@/utils/env";
import { useRoute } from "vue-router";
import { ref, reactive, onMounted, defineAsyncComponent } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import { useQuasar } from "quasar";

/* Icons */
import Interactivity from "@/assets/icons/custom/Interactivity.vue";

export default {
  name: "NewContent",
  components: {
    UploadComponent,
    Mural,
    MediaCard,
    DefaultBtn,
    DownloadFileCard,
    RoundedBtn,
    NewContentToLessonsCard,
    NewContentToLessonsCardForLive,
    draggable: VueDraggableNext,
    LoadingIcon,
    DialogMedia,
    Interactivity,
    DialogCreateLiveClass: defineAsyncComponent(() =>
      import("@/components/liveClass/Dialog/DialogCreateLiveClass.vue")
    ),
  },
  setup() {
    let $q = new useQuasar();
    let postForm = reactive({
      mediaId: null,
      gameId: null,
      tagSelected: "",
      titlePost: "",
      description: "",
      restinctPost: [],
      file: null,
      gameImage: null,
    });
    let breadcrumbs = ref([]);
    let status = ref(true);
    const file = ref(null);
    let fileType = ref("");
    let isShowGameForm = ref(false);
    let isShowMediaForm = ref("false");
    let medias = ref([]);
    let loading = ref(false);
    let loadingPostValue = ref(0);
    let medias_count = ref(0);
    let url_aws_bucket = ENV.URL_AWS_BUCKET;
    let trailDataServices = new TrailDataServices();
    let dataPostion = ref([]);
    let route = new useRoute();
    let breadcrumbsDataServices = new BreadcrumbsDataServices();
    let courseId = route.query.courseId;
    let trainingId = route.query.trainingId;
    let postId = route.query.postId;
    let isShowMedia = ref(false);
    let showCreateLiveClass = ref(false);
    const liveClass = ref([]);

    async function alertRemoveMedia(id, type) {
      $q.notify({
        message: "Deseja remover conteúdo",
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              await removeMedia(id, type);
            },
          },
        ],
      });
    }

    async function getAllLiveClass() {
      await trailDataServices
        .getAllLiveClass(trainingId)
        .then((response) => {
          if (liveClass.value.length > 0) {
            liveClass.value = [];
          }
          response.data.forEach((r) => {
            if (!r.delete_at) {
              liveClass.value.push(r);
            }
          });
        })
        .catch((error) => {
          $q.notify({
            message: `Esse erro pode ter sido causado por um problema com o sistema ou com a conta do usuário. <br/>
          Por favor, tente novamente mais tarde. Se o erro persistir, informe o seu administrador.`,
            type: "negative",
            html: true,
            caption: `${error?.message},<br/> ${error?.response?.data}`,
            position: "top",
          });
        });
    }

    async function removeMedia(id, type) {
      medias.value = medias.value.filter((media) => {
        return media.id != id;
      });

      if (["quiz", "evaluation"].includes(type)) {
        await trailDataServices.disabledQuiz(courseId, trainingId, id);
      } else if (type == "game") {
        await trailDataServices.disabledContentGame(courseId, trainingId, id);
      } else {
        await trailDataServices.disabledContentMedia(courseId, trainingId, id);
      }
    }

    function removeLive(id) {
      $q.notify({
        message: "Deseja remover conteúdo",
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              const formData = new FormData();

              formData.append("liveClassId", id);

              await trailDataServices
                .deleteLiveClasses(formData)
                .then((response) => {
                  if (response.data) {
                    liveClass.value = liveClass.value.filter((live) => {
                      return live.id != id;
                    });
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            },
          },
        ],
      });
    }

    async function getAllContentMedia(courseId, trainingId) {
      let data = await trailDataServices.getAllContentStudent(
        courseId,
        trainingId
      );

      data.data.forEach((content) => {
        if (
          content?.status == 1 ||
          content?.state ||
          ["quiz", "evaluation"].includes(content.type)
        ) {
          medias.value.push(content);
        }
      });
      medias_count.value = medias.value.length;
    }

    async function validateAndAddGame() {
      loading.value = true;

      let courseId = route.query.courseId;
      let trainingId = route.query.trainingId;

      const formData = new FormData();
      formData.append("Scorm", postForm.file);
      formData.append("Title", postForm.titlePost);
      formData.append("Image_game", postForm.gameImage);

      const gameId = postForm.gameId;

      if (gameId == null) {
        await trailDataServices
          .addGame(courseId, trainingId, formData)
          .then((response) => {
            loading.value = false;
            window.location.reload();
          })
          .catch((error) => {
            _alertOf(error.response.data.toString());
            loading.value = false;
          });
      } else {
        await trailDataServices
          .editGame(courseId, trainingId, gameId, formData)
          .then((response) => {
            loading.value = false;
            window.location.reload();
          })
          .catch((error) => {
            _alertOf(error.response.data.toString());
            loading.value = false;
          });
      }
    }

    async function validateAndAddMedia() {
      loading.value = true;

      let courseId = route.query.courseId;
      let trainingId = route.query.trainingId;

      const formData = new FormData();
      formData.append("file", postForm.file);
      formData.append("title", postForm.titlePost);
      formData.append("level", 1);

      const mediaId = postForm.mediaId;

      if (mediaId == null) {
        await trailDataServices
          .addMedia(courseId, trainingId, formData, _loadingPublish)
          .then((response) => {
            loading.value = false;
            window.location.reload();
          })
          .catch((error) => {
            _alertOf(error.response.data.toString());
            loading.value = false;
          });
      } else {
        await trailDataServices
          .editMedia(courseId, trainingId, mediaId, formData)
          .then((response) => {
            loading.value = false;
            window.location.reload();
          })
          .catch((error) => {
            _alertOf(error.response.data.toString());
            loading.value = false;
          });
      }
    }

    function _loadingPublish(value) {
      loadingPostValue.value = value;
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }
    function reorderTraining() {
      dataPostion.value = [];
      medias.value.forEach((element, indice) => {
        dataPostion.value.push({
          id: element.id,
          type: element.type,
          trail_order: indice,
        });
      });
    }

    async function saveOrderTraining() {
      loading.value = true;
      await trailDataServices.updateReorderContent(
        courseId,
        trainingId,
        dataPostion.value
      );
      loading.value = false;
    }

    function openFileSelect(typeFile) {
      if (typeFile == "x-zip-compressed") {
        postForm.gameId = null;
        fileType.value = typeFile;
        isShowGameForm.value = true;
      }
      if (["video", "application"].includes(typeFile)) {
        postForm.mediaId = null;
        fileType.value = typeFile;
        isShowMediaForm.value = true;
      } else if (typeFile == "img") {
        postForm.mediaId = null;
        fileType.value = typeFile;
        isShowMedia.value = true;
      }
    }

    function openFileSelectEdit(element) {
      postForm.titlePost = element.title;
      if (element.type == "game") {
        postForm.gameId = element.id;
        fileType.value = "x-zip-compressed";
        isShowGameForm.value = true;
      } else if (["png", "jpg"].includes(element.type)) {
        postForm.mediaId = element.id;
        isShowMedia.value = true;
      } else {
        postForm.mediaId = element.id;
        isShowMediaForm.value = true;
      }
    }

    function checkFileType(files) {
      return files.filter((file) => {
        let type = file.type.split("/");
        if (type[0] === fileType.value) {
          postForm.file = files[0];
          return file;
        }
      });
    }

    function checkFileTypeZip(files) {
      return files.filter((file) => {
        let type = file.type.split("/");
        if (type[1] === fileType.value || file.type.includes("zip")) {
          postForm.file = files[0];
          return file;
        }
      });
    }

    function imageFile(files) {
      postForm.file = files[0];
      return files;
    }

    function imageGame(files) {
      postForm.gameImage = files[0];
      return files;
    }

    async function GetBreadcrumbsNewContent(courseId, trainingId) {
      let result = await breadcrumbsDataServices.GetBreadcrumbsTrainingPage(
        courseId,
        trainingId
      );
      breadcrumbs.value = [result.data.split("/")[0], "Adicionar conteúdo"];
    }

    function onRejected(
      rejectedEntries = "O formato do arquivo não pode ser aceito"
    ) {
      $q.notify({
        type: "negative",
        message: `${rejectedEntries}`,
      });
    }

    onMounted(() => {
      getAllContentMedia(route.query.courseId, route.query.trainingId);
      getAllLiveClass();
      reorderTraining();
      GetBreadcrumbsNewContent(route.query.courseId, route.query.trainingId);
    });

    return {
      imageFile,
      postForm,
      status,
      url_aws_bucket,
      medias,
      medias_count,
      breadcrumbs,
      loading,
      loadingPostValue,
      file,
      courseId,
      trainingId,
      postId,
      isShowGameForm,
      isShowMediaForm,
      checkFileTypeZip,
      validateAndAddGame,
      validateAndAddMedia,
      removeMedia,
      alertRemoveMedia,
      openFileSelect,
      reorderTraining,
      fileType,
      checkFileType,
      onRejected,
      openFileSelect,
      openFileSelectEdit,
      saveOrderTraining,
      imageGame,
      isShowMedia,
      showCreateLiveClass,
      liveClass,
      removeLive,
      getAllLiveClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}

.media,
.add-contents {
  height: 100%;
  overflow-y: auto;

  .add-evaluation {
    width: 200px;
  }

  .add-button {
    width: 250px;
  }

  .media-card-section {
    width: 80vh;
    display: flex;
  }
}
</style>
