<template>
  <div class="flex column justify-center items-center notification-card">
    <div class=" row items-center main">
      <div class="row no-wrap col-12 justify-around">
        <!-- col-1  -->
        <div class="q-pa-sm">
          <img class="" :src="docImg" alt="extension_file">
        </div>
        <!-- col-9 -->
        <div class="q-pl-sm flex items-center justify-center">
          <div class="column">
            <h6>{{ title }}</h6>
            <small>{{  dateFormart }}</small>
          </div>
        </div>
        <!-- col-2  -->
        <div class="flex items-center">
<!--          <iframe name="iframe_download" class="hidden"></iframe>-->
          <a :href="link" download target="_blank" @click="$emit('publish')" >
            <img :src="downloadImg" alt="download">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//----IMAGES
import docImg from '@/assets/images/file_docx.svg';
import downloadImg from '@/assets/icons/download.svg';

import RoundedBtn from "@/components/shared/buttons/RoundedBtn.vue";
import { date } from 'quasar';

export default {
  name: "DownloadFileCard",
  components: {
    RoundedBtn,
    date
  },
  emits:["publish"],
  props: {
    title: String,
    date_created: String,
    link: String,
    extension: String,
  },
  setup(props){

     let dateFormart = date.formatDate(props.date_created, 'DD/MM/YYYY H:mm');

     return {
      //----IMAGE
      docImg,
      downloadImg,

       dateFormart
     };
  }
};
</script>

<style lang="scss" scoped>
.notification-card {
  width: 100%;
  height: 15vh;
  margin-bottom: 1rem;
  padding: 3% 3%;
  background: #fdfdfd;
  border-radius: 20px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
  0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
}

.banner {
  width: 95%;
  height: 188px;
  overflow: hidden;
  margin-bottom: 2%;
  border-radius: 20px;
}

.main {
  width: 100%;
  position: relative;

  .avatar-notification {
    width: 35px;
    height: 35px;
    margin: 0 3%;
    overflow: hidden;
    border-radius: 35px;
  }

  .title-notification {
    flex: 1;

    h6 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 17px;
    }

    span {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 17px;
    }

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 0.62rem;
      line-height: 12px;
      color: #0000004d;

    }
  }
}

</style>
