<template>
  <q-dialog v-model="isShowDialog" full-width persistent>
    <q-card>
      <q-card-section class="column items-left dialog-main">
        <h6>
          Adicionar um {{ fileType == "application" ? "Documento" : "vídeo" }}
        </h6>
        <!-- <q-uploader
          url="http://localhost:8080/upload"
          color="default-pink"
          square
          flat
          bordered
          hide-upload-btn
          :filter="checkFileType"
          @rejected="
            onRejected(
              `O formato é diferente de ${
                fileType == 'application' ? 'Documento' : 'vídeo'
              }`
            )
          "
          style="max-width: 300px"
        /> -->
        <div class="col-12">
          <label class="title-form">Capa</label>
        </div>
        <div class="col-12 thumb-training q-mt-sm shadow-3">
          <q-img
            height="150px"
            :src="imageUrl"
            fit="contain"
            style="background-color: #e5e5e5"
          />
        </div>
        <div class="col-12 btn-thumb-training flex justify-between q-mt-md">
          <q-btn
            @click="sendEventAddImage"
            class="q-mt-xs"
            unelevated
            color="default-pink"
            text-color="default-white"
            label="Escolher imagem"
          />
          <input
            style="display: none"
            ref="refFileInput"
            @change="onEventFilePicked"
            type="file"
            name="upload"
            accept="image/*"
          />

          <q-btn
            @click="sendEventRemoveImage"
            class="q-mt-xs"
            outline
            color="default-pink"
            label="Remover"
          />
        </div>
      </q-card-section>
      <q-card-section>
        <!-- <q-input label="Título" v-model="postForm.titlePost"></q-input> -->
      </q-card-section>
      <q-card-actions align="right">
        <!-- <q-btn
          text-color="default-pink"
          dense
          flat
          label="Cancelar"
          color="primary"
          v-close-popup
        />
        <q-btn
          color="default-pink"
          text-color="white"
          no-caps
          unelevated
          dense
          label="Salvar"
          @click="validateAndAddMedia"
          v-close-popup
        /> -->
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "DialogMedia",
  props: {
    dataBaseQuestions: Array,
    showDialog: Boolean,
  },
  components: {},
  emits: ["showQuestions"],
  setup() {
    const imageUrl = ref("");
    const file = ref(null);
    const refFileInput = ref(null);
    const isShowDialog = ref(props.showDialog);

    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    const sendEventRemoveImage = () => {
      // imageUrl.value = "";
      // refFileInput.value = null;
      // file.value = "";
    };

    const onEventFilePicked = (event) => {
      // const files = event.target.files;
      // file.value = files[0];
      // const filename = files[0].name;
      // if (filename.lastIndexOf(".") <= 0) {
      //   return alert("Por favor adicione um arquivo válido");
      // }
      // const fileReader = new FileReader();
      // fileReader.addEventListener("load", (event) => {
      //   imageUrl.value = fileReader.result;
      // });
      // fileReader.readAsDataURL(files[0]);
    };

    const sendEventAddImage = () => {
      // refFileInput.value.click();
    };

    return {
      sendEventRemoveImage,
      onEventFilePicked,
      sendEventAddImage,
      isShowDialog,
    };
  },
};
</script>

<style lang="scss" scoped></style>
