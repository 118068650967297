<template>
  <div class="flex column justify-center items-center notification-card">
    <div
      class="flex row items-center justify-between main"
      :class="[getColorBgBadge('add')]"
    >
      <div class="icon-type q-mr-sm">
        <BadgeType typeBadge="add" iconBadge="video_camera_front" />
      </div>
      <div class="title-notification">
        <h6>{{ content.title }}</h6>
        <small>adicionando em {{ dateFormart }}</small>
      </div>
      <div>
        <q-btn
          flat
          dense
          color="secondary"
          icon="delete"
          @click="$emit('removeItem', content.id, content.type)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BadgeType from "@/components/BadgeType.vue";
import { ref } from "vue";
import { date } from "quasar";
import ColorsClass from "@/utils/styles/colors/getColors.js";
/* Services */
import TrailDataServices from "@/services/TrailDataServices";

/* VUEROUTER */
import { useRoute } from "vue-router";

export default {
  name: "NewContentToLessonsCardForLive",
  components: {
    BadgeType,
  },
  emits: ["publish", "removeItem"],
  props: {
    content: Object,
    date_created: String,
  },
  setup(props) {
    //-----CONSTANTE
    const Colors = new ColorsClass();
    const route = new useRoute();
    const _TrailDataServices = new TrailDataServices();

    let dateFormart = ref(date.formatDate(props.date_created, "DD/MM/YYYY"));
    let course_id = route.query.courseId;
    let training_id = route.query.trainingId;
    let post_id = route.query.postId;

    function getColorBgBadge(name) {
      return Colors.getColorBgBadge(name);
    }

    async function releaseFix(quizId) {
      await _TrailDataServices
        .releaseFix(quizId, true)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return {
      dateFormart,
      getColorBgBadge,
      course_id,
      training_id,
      post_id,
      releaseFix,
    };
  },
};
</script>

<style lang="scss" scoped>
.notification-card {
  width: 100%;
  padding: 2%;
  border-radius: 20px;
  background: #fbfbfb;
  box-shadow: 3px 6px 15px rgba(0, 0, 0, 0.1);
  cursor: grab;
}
.notification-card:active {
  cursor: grabbing;
}

.banner {
  width: 95%;
  height: 188px;
  overflow: hidden;
  margin-bottom: 2%;
  border-radius: 20px;
}

.main {
  width: 100%;
  position: relative;

  &::before {
    content: "";
    width: 3px;
    height: 35px;
    position: absolute;
    left: -2.2%;
    border-radius: 0px 2px 2px 0px;
  }

  .item-bg-pink {
    &::before {
      background-color: var(--q-primary, #fe4e64);
    }
  }
  .item-bg-yellow {
    &::before {
      background-color: #d49a41;
    }
  }
  .item-bg-purple {
    &::before {
      background-color: #a55ab3;
    }
  }
  .item-bg-blue {
    &::before {
      background-color: #3f8ab3;
    }
  }
  .item-bg-green {
    &::before {
      background-color: #a1cb72;
    }
  }
  .item-bg-depp-green {
    &::before {
      background-color: #439380;
    }
  }
  .title-notification {
    flex: 1;
    h6 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 17px;
    }
    span {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 17px;
    }

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 0.62rem;
      line-height: 12px;
      color: #0000004d;
    }
  }

  .icon-type {
    width: 45px;
    height: 45px;
  }

  .action-card {
    cursor: pointer;
  }
}
</style>
