<template>
  <div class="col-12 flex row q-pa-sm">
    <div class="col-12">
      <label class="title-form">Capa</label>
    </div>
    <div class="col-12 thumb-training q-mt-sm shadow-3">
      <q-img
          height="150px"
          :src="imageUrl"
          fit="contain"
          style="background-color: #e5e5e5"
      />
    </div>
    <div class="col-12 btn-thumb-training flex justify-between q-mt-md">
      <q-btn
          @click="sendEventAddImage"
          class="q-mt-xs"
          unelevated
          color="default-pink"
          text-color="default-white"
          label="Escolher imagem"
      />
      <input
          style="display: none"
          ref="refFileInput"
          @change="onEventFilePicked"
          type="file"
          name="upload"
          accept="image/*"
      />
      <q-btn
          @click="sendEventRemoveImage"
          class="q-mt-xs"
          outline
          color="default-pink"
          label="Remover"
      />
    </div>
  </div>

</template>

<script>
import {ref, reactive, onMounted, emi} from "vue";

export default {
  name: "UploadComponent",
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const imageUrl = ref("");
    const refFileInput = ref(null);
    const file = ref(null);


    const sendEventRemoveImage = () => {
      imageUrl.value = "";
      refFileInput.value = null;
      file.value = "";
    };

    const onEventFilePicked = (event) => {
      const files = event.target.files;
      file.value = files[0];
      const filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Por favor adicione um arquivo válido");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", (event) => {
        imageUrl.value = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      emit('update:modelValue', file.value);
    };

    const sendEventAddImage = () => {
      refFileInput.value.click();
    };

    return {
      imageUrl,
      refFileInput,
      file,
      sendEventAddImage,
      sendEventRemoveImage,
      onEventFilePicked,
    }
  }
}
</script>

<style>

</style>